.h1-admindashboard {
    /*background-color: black;*/
    color: black;
    text-align: right;

  }

  .p-dashboard{
    color: white;
  }

  .body-dashboard {
    /*background-image: url("./SignInBg.png");*/
    /*background-size: cover;*/
    background-color: azure;
    /*display: flex;*/ /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    overflow: auto;
    /*height: 100vh;
    width: 100vw;*/
}
  .dashboard-container {
    display: flex;
    height: 100%;
  }
  
  .dashboard-content {
    margin-left: 15em;
    padding: 20px;
    width: 100%;
  }

  .dashboard-function-container {
    background-color: rgba(190,52,85);
    width: 15em;
    height: 15em;
    /*align-items: center;*/
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    /*justify-content: space-around;*/
    top: 100px;
    left: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.dashboard-function-container:hover, .dashboard-icon:hover {
  background: #e89d13;
  /*color: #000;*/
}

.dashboard-function-container-admin {
  /*background: linear-gradient(to right, rgb(101, 44, 139), rgb(212, 33, 125));*/
  background: linear-gradient(to right, #662d8c, #e31f7b);
  width: 15em;
  height: 15em;
  /*align-items: center;*/
  position: relative;
  border-radius: 1em;
  box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
  /*justify-content: space-around;*/
  top: 100px;
  left: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-function-container-btecadmin {
  /*background: linear-gradient(to right, rgb(101, 44, 139), rgb(212, 33, 125));*/
  background: linear-gradient(to right, #662d8c, #e31f7b);
  width: 10em;
  height: 10em;
  /*align-items: center;*/
  position: relative;
  border-radius: 1em;
  box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
  /*justify-content: space-around;*/
  top: 10px;
  left: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dashboard-icon-admin-new {
  font-size: 180px; /* Adjust the size as desired */
  color: #ffffff;
  /*box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.4); /* Add the shadow effect */
}

.dashboard-icon-admin-btec {
  font-size: 100px; /* Adjust the size as desired */
  color: #ffffff;
  /*box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.4); /* Add the shadow effect */
}

.dashboard-function-container-btecadmin:hover, .dashboard-icon-admin-btec:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
}

.dashboard-function-container-btecadmin.active, .dashboard-icon-admin-btec.active {
  background: linear-gradient(to right, #ed2377, #f56e59);
}



.dashboard-function-container-admin:hover, .dashboard-icon-admin-new:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  /*background: #e89d13;
  color: black;*/
  /*background: #0c709e;*/
/*color: #000;*/
}

.dashboard-icon {
  font-size: 180px; /* Adjust the size as desired */
  color: #ffffff;
  /*box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.4); /* Add the shadow effect */
}

.p-dashboard-container {
  color: #ffffff;
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5); /* Added text shadow */
  font-size: 18px;
}