.h1-generateqr {
    /*background-color: black;*/
    color: black;
    text-align: right;
    text-align: center;
   /* float: right;*/
  }

  .p-qrattend{
    color: black;
  }

  .p-addstudent2{
    color: white;
  }

  .p-generateqr{
    color: black;
    /*font-weight: bold;*/
    font-size: 20px;
  }

  .p-generateqr2{
    color: black;
    /*font-weight: bold;*/
    font-size: 16px;
    text-align: center;
  }

  .p-generateqr3{
    color: black;
    /*font-weight: bold;*/
    align-items: center;
    text-align: center;
    margin: auto;
    display: block;
  }

  .p-generateqr4{
    color: black;
    /*font-weight: bold;*/
    align-items: center;
    text-align: center;
    margin: auto;
    display: block;
    font-weight: bold;
  }

  .p-generateqr-sidenote{
    color: black;
    font-size: 13px;
  }

  .p-addstudent3{
    color: black;
    text-align: center;
  }

  .p-addstudent4{
    color: black;
    text-align: center;
    font-weight: bold;
  }

  .body-generateqr {
    /*background-image: url("../SignInBg.png");
    background-size: cover;*/
    /*background-color: aliceblue;*/
    /*display: flex;*/ /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    overflow: auto;
    /*height: 100vh;
    width: 100vw;*/
}

.addstudent-container {
    background-color: rgba(112, 40, 40, 0.466);
    width: 80em;
    height: 40em;
    align-items: center;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    justify-content: space-around;
}

.addstudent-container2 {
  background-color: rgb(232, 232, 232);
  width: 15em;
  height: 3em;
  position: relative;
  border-radius: 1em;
  flex-direction: column;
  justify-content: space-around;
}

.button-addStudent2 {
  width: 13%;
  height: 3em;
  border: 0px;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-generateqr {
    width: 100px;
    height: 3em;
    border: 0px;
    background-color: rgb(190,52,85);
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    display: block;
    left: 150px;
    margin: auto;
    margin-left: 10px;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
  }

  .button-generateqr:hover {
    background-color: orange;
  }

  .button-generateqr-new {
    width: 100px;
    height: 3em;
    border: 0px;
    background: linear-gradient(to right, #662d8c, #e31f7b);
    /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    display: block;
    left: 150px;
    margin: auto;
    margin-left: 10px;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
  }

  .button-generateqr-new:hover {
    background: linear-gradient(to right, #ed2377, #f56e59);
    transition: 0.3s;
    /*background: #e89d13;
    color: black;*/
  }

.checkbox-size-addstudent {
  font-family: system-ui, sans-serif;
  font-size: 5px;
  font-weight: bold;
  line-height: 1.1;

}

.select-size-generateqr {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.5em;
  width: 25em;
  border-radius: 0.25em;
  padding: 1em;
  align-items: center;
  display: block;
  margin: auto;
  text-align: center;
  font-weight: bold;
}

.select-size-generateqr3 {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.5em;
  width: 10em;
  border-radius: 0.25em;
  padding: 1em;
  align-items: center;
  display: block;
  font-weight: bold;
}

.select-size-generateqr2 {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.5em;
  width: 5em;
  border-radius: 0.25em;
  padding: 1em;
  /*align-items: center;*/
  display: block;
  /*margin: auto;
  /*text-align: center;*/
  font-weight: bold;
}

.select-size-generateqr4 {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.5em;
  width: 5em;
  border-radius: 0.25em;
  padding: 1em;
  align-items: center;
  display: block;
  font-weight: bold;
}

.select-size-addstudent2 {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.0em;
  width: 6em;
  border-radius: 0.25em;
  padding: 1em;
}

.select-size-addstudent3 {
border: none;
background-color: rgb(232, 232, 232);
height: 3.0em;
width: 5em;
border-radius: 0.25em;
padding: 1em;
margin-left: .5rem;
}

.addstudent-row {
  margin-left:-5px;
  margin-right:-5px;
}

.addstudent-
{
  float: left;
  width: 50%;
  padding: 5px;
}

.button-addStudent3 {
  width: 13%;
  height: 3em;
  border: 0px;
  float: left;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.table-generateqr {
  border-collapse: collapse;
  width: 100%;
  /*height: 100%;*/
  height: 600px;
  align-items: center;
  overflow: auto;
  font-size: 14px;
  color: #333;
  display: block;
  
  /*margin-left: 10px;*/
}

.table-generateqr2 {
  border-collapse: collapse;
  width: 100%;
  /*height: 100%;*/
  height: 450px;
  align-items: center;
  overflow: auto;
  font-size: 14px;
  color: #333;
  display: block;
  /*margin-left: 10px;*/
}

.generateqr-td{
table-layout: fixed;
width: 450px;
}

.generateqr-td .cell-content {
  max-height: 100px;
  overflow: auto;
}

.generateqr-column{
  float: left;
  width: 50%;
  padding: 5px;

}

.th-generateqr {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.p-createclass{
  color: black;
  text-align: center;
}

.addstudent-container3 {
  background-color: rgb(232, 232, 232);
  width: 30em;
  height: 2.3em;
  position: relative;
  /*margin-left: 10px;*/
  border-radius: 10px;
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-around;
}

.generateqr-search-container{
  float: right;
}

.button-generateqr2 {
  width: 13%;
  height: 3em;
  border: 0px;
  color: white;
  background-color: rgb(190,52,85);
  display: block;
  float: left;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}


.button-addStudent4 {
  width: 5%;
  height: 2.3em;
  border: 0px;
  /*float: left;*/
  background-color:rgb(232, 232, 232);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: -17px;
  transition: 0.3s;
  border-radius: 12px;
  cursor: pointer;
}

.find-student-position{
  margin-left: 10px;
  align-items: center;
}

.addstudent-next-to{
  display: flex;
  align-items: center; /* Align elements vertically in the center */
  gap: 10px; /* Add some space between elements */
  
}

/*.table-container {
  display: flex;
  overflow: auto;
  max-height: 210px; /* set the maximum height of the container */
  /*max-width: 600px; /* set the maximum height of the container */
/*}*/

/*.student-list {
  margin-right: 20px; /* Adjust spacing between the lists */
/*}*/

.button-GenQR {
  width: 150px;
  height: 40px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background-color: rgb(190,52,85);*/
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 5px;
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
}

.button-GenQRv2 {
  width: 90px;
  height: 40px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background-color: rgb(190,52,85);*/
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;

  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQRv2:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
}

.button-GenQR-new {
  width: 150px;
  height: 40px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 5px;
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR-new:hover {
  /*background: #e89d13;
  color: black;*/
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
}

.button-GenQR-container {
  display: flex;
  justify-content: center;
}

.button-GenQR2 {
  width: 85px;
  height: 45px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background-color: rgb(190,52,85);*/
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center; /* Align items vertically in the center */
  margin: 5px;
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR2:hover {
  /*background: linear-gradient(to right, #662d8c, #e31f7b);*/
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
  /*background-color: orange;*/
}

.button-GenQR2-container {
  display: flex;
  justify-content: center;
}

.button-GenQR2-new {
  width: 85px;
  height: 45px;
  border: 0px;
  color: white;
  /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  background: linear-gradient(to right, #662d8c, #e31f7b);
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center; /* Align items vertically in the center */
  margin: 5px;
  /*margin-left: 1px;*/
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR2-new:hover {
  /*background: #e89d13;
  color: black;*/
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
}

.button-GenQR2-new-container {
  display: flex;
  justify-content: center;
}

.button-GenQR3 {
  width: 150px;
  height: 65px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background-color: rgb(190,52,85);*/
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center; /* Align items vertically in the center */
  margin: 5px;
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR3:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
  /*background-color: orange;*/
}

.button-GenQR3-container {
  display: flex;
  justify-content: center;
}

.button-GenQR3-new {
  width: 150px;
  height: 65px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center; /* Align items vertically in the center */
  margin: 5px;
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR3-new:hover {
  /*background: #e89d13;
  color: black;*/
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
  /*background: rgb(12, 115, 160);*/
}

.button-GenQR3-new-container {
  display: flex;
  justify-content: center;
}

.button-GenQR4 {
  width: 150px;
  height: 45px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background-color: rgb(190,52,85);*/
  font-weight: bold;
  font-size: 12px;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  /*display: block;*/
  display: flex;
  align-items: center; /* Align items vertically in the center */
  margin: 5px;
  /*margin-left: 1px;*/
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR4:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
}

.button-GenQR4-container {
  display: flex;
  justify-content: center;
}

.button-GenQR4-new {
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  width: 150px;
  height: 45px;
  border: 0px;
  color: white;
  /*background-color: rgb(190,52,85);*/
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin: 5px;
  /*margin-left: 1px;*/
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR4-new:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
  /*background: #e89d13;
  color: black;*/
}

.button-GenQR4-new-container {
  display: flex;
  justify-content: center;
}

.button-GenQR5 {
  width: 180px;
  height: 45px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background-color: rgb(190,52,85);*/
  font-weight: bold;
  font-size: 12px;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  /*display: block;*/
  display: flex;
  align-items: center; /* Align items vertically in the center */
  margin: 5px;
  /*margin-left: 1px;*/
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR5:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
}

.button-GenQR5-container {
  display: flex;
  justify-content: center;
}

.button-GenQR6 {
  width: 80px;
  height: 45px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background-color: rgb(190,52,85);*/
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center; /* Align items vertically in the center */
  margin: 5px;
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR6:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
  /*background-color: orange;*/
}

.button-GenQR6-container {
  display: flex;
  justify-content: center;
}

.button-GenQR7 {
  width: 80px;
  height: 45px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background-color: rgb(190,52,85);*/
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center; /* Align items vertically in the center */
  /*margin: 5px;*/
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR7:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
background-color: orange;
}

.button-GenQR7-container {
  display: flex;
  justify-content: center;
}

.button-GenQR8 {
  width: 70px;
  height: 35px;
  border: 0px;
  color: white;
  /*background: linear-gradient(135deg, rgb(190,52,85), rgb(255,155,0), rgb(52, 170, 220));*/
  /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  background: linear-gradient(to right, #662d8c, #e31f7b);
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center; /* Align items vertically in the center */
  margin: 5px;
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR8:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  /*background: #e89d13;
  color: black;*/
}

.button-GenQR8-container {
  display: flex;
  justify-content: center;
}

.button-GenQR8low {
  width: 70px;
  height: 35px;
  border: 0px;
  color: white;
  /*background: linear-gradient(135deg, rgb(190,52,85), rgb(255,155,0), rgb(52, 170, 220));*/
  /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  background: linear-gradient(to right, #662d8c, #e31f7b);
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center; /* Align items vertically in the center */
  margin-left: 20px;
  margin-top: 14px;
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-GenQR8low:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  /*background: #e89d13;
  color: black;*/
}