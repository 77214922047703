*{
    margin:0;
    padding:0;
    text-decoration: none;
}
.container{
    display:flex;
    
}
main{
    width: 100%;
    padding: 20px;
}
.sidebar{
    background: #BE3455;
    color: #fff;
    overflow: auto;
    /*position: fixed;
    left: 0;
    top: 0;*/
    height: 230vh; /*old value 120vh*/
    width:250px;
    transition: all 0.5s;
}

.sidebar-new{
    /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
    background: linear-gradient(to right, #662d8c, #e31f7b);
    color: #fff;
    overflow: auto;
    /*position: fixed;
    left: 0;
    top: 0;*/
    height: 230vh; /*old value 120vh*/
    width:250px;
    transition: all 0.5s;
}

.sidebar-Admin{
    /*background: linear-gradient(to right, rgb(238, 2, 247), rgb(171, 36, 175), rgb(31, 146, 192));*/
    background: linear-gradient(to right, #662d8c, #e31f7b);
    /*background: #e31f7b;*/
    color: #fff;
    overflow: auto;
    /*position: fixed;
    left: 0;
    top: 0;*/
    height: 230vh; /*old value 120vh*/
    width:250px;
    transition: all 0.5s;
}

.top_section{
    display:flex;
    align-items:center;
    padding: 20px 15px;
}
.logo{
    font-size: 28px;
}
.bars{
    display: flex;
    font-size: 25px;
    margin-left: 30px;
}
.link{
    display: flex;
    color: #fff;
    padding: 10px 15px;
    gap: 15px;
    transition: all 0.5s;
}
.link:hover, .sublink:hover{
    /*background: #e89d13;
    color: #000;*/
    background: linear-gradient(to right, #ed2377, #f56e59);
    /*background: rgb(12, 115, 160);*/
    /*color: #ffffff;*/
    transition: all 0.3s;
    cursor: pointer;
}
.active {
    /*background: #e89d13;*/
    /*background: rgb(12, 115, 160);*/
    background: linear-gradient(to right, #ed2377, #f56e59);
    /*background: #e89d13;*/
    /*color: #000;*/
    color: #ffffff;
}
.icon, .link_text, .sublink_text{
    font-size: 20px;
}

/* Dropdown submenu */
.dropdown{
    margin-left: 30px;
    padding-left: 20px;
    border-left: 1px solid #fff;
}
.sublink{
    display: flex;
    color: #fff;
    padding: 10px 0px;
    gap: 20px;
    transition: all 0.5s;
}
.sublink_text{
    font-size: 16px;
}

.subIcon {
    transition: all 0.5s;
    gap: 20px;
    padding-left: 50px;
}

.circle-sidebar {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    width: 70px;
    height: 70px;
    border-radius: 50%;
    overflow: hidden;
    /*border: 2px solid #000;
    background-color: #fff;*/
  }
  
  .profile-image-sidebar {
    width: 100%;
    height: 50px;
    object-fit: cover;
    /*background-color: #fff;*/
  }

  .btec-image-sidebar {
    width: 110%;
    height: 50px;
    object-fit: cover;
    /*background-color: #fff;*/
  }
  
  .loading-text-username {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .loading-text-username p {
    text-align: center;
  }
