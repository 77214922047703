.h1-addstudent {
    /*background-color: black;*/
    color: black;
    text-align: right;
    text-align: center;
   /* float: right;*/
  }

  .p-addstudent{
    color: black;
  }

  .p-addstudent2{
    color: black;
  }

  .p-addstudent3{
    color: black;
    text-align: center;
  }

  .p-addstudent4{
    color: black;
    text-align: center;
    font-weight: bold;
  }

  .body-addstudent {
    /*background-image: url("../SignInBg.png");*/
    background-size: cover;
    /*display: flex;*/ /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.addstudent-container {
    background-color: rgba(112, 40, 40, 0.466);
    width: 80em;
    height: 40em;
    align-items: center;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    justify-content: space-around;
}

.addstudent-container2 {
  background-color: rgb(232, 232, 232);
  width: 15em;
  height: 3em;
  position: relative;
  border-radius: 1em;
  flex-direction: column;
  justify-content: space-around;
}

.button-addStudent2 {
  width: 13%;
  height: 3em;
  border: 0px;
  font-weight: bold;
  color: white;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-addStudent2:hover {
  background-color: orange;
}

.button-addStudent6 {
  width: 140px;
  height: 3em;
  border: 0px;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background-color: rgb(190,52,85);*/
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-addStudent6:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
  /*background-color: orange;*/
}

.button-addStudent-new6 {
  width: 140px;
  height: 3em;
  border: 0px;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-addStudent-new6:hover {
  background: red;
}

.button-addStudent-new6v2 {
  width: 140px;
  height: 3em;
  border: 0px;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-addStudent-new6v2:hover {
  background: rgb(12, 115, 160);
}

.search-shift-to-right-addstudent {
  margin-right: 10px;

}

.checkbox-size-addstudent {
  font-family: system-ui, sans-serif;
  font-size: 5px;
  font-weight: bold;
  line-height: 1.1;

}

.select-size-addstudent {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.5em;
  width: 25em;
  border-radius: 0.25em;
  padding: 1em;
}

.select-size-addstudent2 {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.0em;
  width: 6em;
  border-radius: 0.25em;
  padding: 1em;
}

.select-size-addstudent3 {
border: none;
background-color: rgb(232, 232, 232);
height: 3.0em;
width: 5em;
border-radius: 0.25em;
padding: 1em;
margin-left: .5rem;
}

.addstudent-row {
  margin-left:-5px;
  margin-right:-5px;
}

.addstudent-column{
  float: left;
  width: 50%;
  padding: 5px;
}

.button-addStudent3 {
  width: 13%;
  height: 3em;
  border: 0px;
  float: left;
  color: white;
  background-color: rgb(190,52,85);
  font-weight: bold;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-addStudent3:hover {
background-color: orange;
}

.button-addStudent5 {
  width: 5%;
  height: 2em;
  border: 0px;
  float: left;
  color: black;
  background-color:white;
  font-weight: bold;
  border: 1px solid black; /* Add a black border */
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-addStudent5.active {
  background-color: orange;
  color: white;
  border: 1px solid black; /* Add a black border */
}

.table-addstudent {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 600px;
  overflow: auto;
  font-size: 14px;
  color: #333;
  display: block;
  /*margin-left: 10px;*/
}

.th-addstudent {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;/*change input colour*/
}

.p-createclass{
  color: black;
  text-align: center;
}

.addstudent-container3 {
  background-color: rgb(232, 232, 232);
  width: 30em;
  height: 2.3em;
  position: relative;
  /*margin-left: 10px;*/
  border-radius: 10px;
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-around;
}

.button-addStudent4 {
  width: 5%;
  height: 2.3em;
  border: 0px;
  /*float: left;*/
  background-color:rgb(232, 232, 232);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: -17px;
  transition: 0.3s;
  border-radius: 12px;
  cursor: pointer;
}

.find-student-position{
  margin-left: 10px;
  align-items: center;
}

.addstudent-next-to{
  display: flex;
  align-items: center; /* Align elements vertically in the center */
  gap: 10px; /* Add some space between elements */
  
}

.addstudent-button-container{
  margin-bottom: 100px;
  float:left;
}