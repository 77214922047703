* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto',sans-serif;
}

.body {
    background-image: url("./SignInBgbright.png");
    background-size: contain;
    background-repeat: no-repeat;
    /*background-size: 100vh;
    background-size: 80vw;*/
    background-position-x: 400px;
    background-repeat: no-repeat;
    display: flex; /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.body2 {
    position: relative; /* Ensure relative positioning for z-index to work */
    background-image: url("./SignInBgbright.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.page {
    height: 100vh; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sign-in-container {
    background-color: white;
    /*width: 25em;
    height: 30em;*/ /*old*/
    width: 25em;
    height: 100vh;
    /*height: 46.2em;*/
    position: absolute;
    top: 0px;
    left:0px;
    /*border-radius: 1em;*/
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.logo-position {
    width: 25em;
    height: 10em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.picture-signin {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: -1; /* Place the background image behind other elements */
    /* Add more styling properties if needed */
}

.user-detail {
    width: 23em;
    height: 13em;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.h2-signin {
    text-align: center;

}

input {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 27.6em;
    border-radius: 0.25em;
    padding: 1em; /*for changing input ui*/
}

input.focus {
    outline-color: rgb(32, 177, 255);
}

button {
    width: 70%;
    height: 3em;
    border: 0px;
    background-color: rgb(190,52,85);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
    margin: auto;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
}

button:hover { 
    transform: scale(1.10);
    background-color: orange;
}

.forgot-user {
    position:relative; 
    width: 110%;
    height: 2em;
    font-size: 11px;
    left:130px; 
    top:0px;
    color: white;
}

.account-notification {
    position:relative;
    color: Black;
    width: 130%;
    left:-15px; 
    height: 2em;
}

.register-link {
    color: rgb(200, 153, 11);
    transition: 0.3s;
}

.register-link:hover {
    box-shadow: 0 5px 15px #afe0f5;
     transform: scale(1.5);
}

.forgot-user-password {
    position:relative; 
    width: 110%;
    height: 2em;
    font-size: 11px;
    left:130px; 
    top:0px;
    font-weight: bold;
    font-size: 15px;
    color: rgb(0, 0, 0);
    transition: 0.3s;
}

.forgot-user-password:hover {
    box-shadow: 0 5px 15px #ff15ff;
     transform: scale(1.5);
     color: darkblue;
    }

    .footer-signin-container {
        position: fixed;
        /*bottom: 0;
        left: 0;*/
        bottom: 0;
        right: 0;
        /*margin: 10px;*/
        left: 0px;
        color: #ff15ff !important;
        font-weight: bold;
        font-size: 11px !important;
        text-shadow: 0 0 5px white, 0 0 2px white, 0 0 2px white, 0 0 10px white !important; /*add shadow*/
    }

    /*.footer-signin-container2 {
        position: fixed;
        bottom: 0;
        right: 0;
        margin: 10px;
        color: white;
        font-weight: bold;
        font-size: 14px;
        text-shadow: 0 0 5px black, 0 0 2px black, 0 0 2px black, 0 0 10px black; /*add shadow*/
    /*}*/

    .footer-signin-container2 {
        position: fixed;
        bottom: 35px;
        justify-content: center;
        color: white;
        font-weight: bold;
        font-size: 16px;
        text-shadow: 0 0 5px black, 0 0 2px black, 0 0 2px black, 0 0 10px black; /*add shadow*/
    }

    .button-signin-user-new2 {
        width: 300px;
        height: 35px;
        border: 0px;
        color: white;
        /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
        background: linear-gradient(to right, #662d8c, #e31f7b);
        font-weight: bold;
        font-size: 12px;
        display: flex;
        align-items: center; /* Align items vertically in the center */
        margin: 5px;
        transition: 0.3s;
        border-radius: 0.9em;
        cursor: pointer;
      }
      
      .button-signin-user-new2:hover {
        background: linear-gradient(to right, #ed2377, #f56e59);
        /*background: #e89d13;
        color: black;*/
        /*background: linear-gradient(to left, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
      }
      
      .button-signin-user-container {
        display: flex;
        justify-content: center;
      }

      .button-signin-user-new3 {
        width: 300px;
        height: 35px;
        border: 0px;
        color: white;
        /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
        background: linear-gradient(to right, #662d8c, #e31f7b);
        font-weight: bold;
        font-size: 12px;
        display: flex;
        align-items: center; /* Align items vertically in the center */
        margin: 5px;
        transition: 0.3s;
        border-radius: 0.9em;
        cursor: pointer;
      }
      
      .button-signin-user-new3:hover {
        background: linear-gradient(to right, #ed2377, #f56e59);
        transition: 0.3s;
        background: linear-gradient(to right, #ed2377, #f56e59);
        /*background: #e89d13;
        color: black;*/
        /*background: linear-gradient(to left, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
      }