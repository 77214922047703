.h2-profile{
  /*background-color: black;*/
  color: black;
  text-align: center;
  font-size: 30px;
}

.p-profile{
  color: black;
  text-align: center;
}

.p-profile2{
  color: black;
  /*font-weight: bold;*/
  font-size: 16px;
  text-align: center;
}

.p-profile3{
  color: black;
  /*font-weight: bold;*/
  font-size: 16px;
}

.p-profile4{
  color: black;
  /*font-weight: bold;*/
  font-size: 16px;
  left: 400px;
}

.profile-instruction-container{
margin-left: -350px;
margin-top: -100px;

}

.preview-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* To stack the items vertically */

}

.button-profile {
  background-color: rgb(190,52,85);
  font-weight: bold;
  width: 10%;
  color: white;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: inline-block;
  margin: auto;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-profile:hover {
  background-color: orange;
}

/*.button-profile-new {
  background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));
  font-weight: bold;
  width: 10%;
  color: white;
  display: inline-block;
  margin: auto;
  transition: 0.5s ease;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-profile-new:hover {
  background:  rgb(12, 115, 160);;
}*/

.button-profile-new {
  /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  background: linear-gradient(to right, #662d8c, #e31f7b);
  font-weight: bold;
  width: 10%;
  color: white;
  display: inline-block;
  margin: auto;
  border-radius: 0.25em;
  cursor: pointer;
  transition: background-color 0.5s; /* Apply a smooth transition to the background color */
}

.button-profile-new:hover {
  /*background: #e89d13;
  color: black;*/
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;
}

.button-profile2 {
  color: white;
  background-color: rgb(190,52,85);
  font-weight: bold;
  width: 20%;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  margin: auto;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-profile2:hover {
background-color: orange;
}

.button-profile-new2 {
  /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  background: linear-gradient(to right, #662d8c, #e31f7b);
  font-weight: bold;
  width: 20%;
  color: white;
  display: block;
  margin: auto;
  border-radius: 0.25em;
  cursor: pointer;
  transition: background-color 0.5s; /* Apply a smooth transition to the background color */
}

.button-profile-new2:hover {
  /*background: #e89d13;
  color: black;*/
  background: linear-gradient(to right, #ed2377, #f56e59);
  transition: 0.3s;

}

.table-container {
overflow: auto;
max-height: 210px; /* set the maximum height of the container */

}

.circle {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid lightgrey;
  cursor: pointer;
}

.circle2 {
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid lightgrey;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.loading-text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.loading-text p {
  text-align: center;
}

.icon-container {
  position: absolute;
  bottom: 485px; /* Adjust the value to change the vertical position */
  left: 25%; /* Align the icon horizontally in the center */
  transform: translateX(-50%); /* Center the icon horizontally */
  width: 30px; /* Adjust the width of the container */
  height: 30px; /* Adjust the height of the container */
 /* background-color: #ffffff; /* Set the background color of the container */
  border-radius: 50%; /* Make the container a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
}

.icon {
  width: 30px; /* Adjust the width of the icon */
  height: 30px; /* Adjust the height of the icon */
  /* Add any additional styling for the icon */
}

.input-icon-container-profile {
  position: relative;
  display: inline-block;
}

.profile-icon {
  position: absolute;
  font-size: 40px;
  bottom: -20%;
  right: 10%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: transform 0.3s ease; /* Add transition for smooth animation */
}

/* Scale up the icon when hovering */
.profile-icon:hover {
  transform: translateY(-50%) scale(1.2);
}

/* Styles for the ReactCrop component */

.modal-profile {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; /* Ensure the modal is on top of other elements */
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/* Add some additional styles to center the Cropper inside the modal */
/*.ReactCrop__crop-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px; /* Adjust this value to set the minimum height of the Cropper area */
/*}*/

/* Styles for the ReactCrop component */
.ReactCrop__crop-container {
  /* Set a fixed size for the crop container */
  width: 300px; /* Adjust the width of the crop container */
  height: 300px; /* Adjust the height of the crop container */
}

/* Customize the appearance of the cropping area */
.ReactCrop__crop-selection {
  border: 2px dashed rgba(255, 255, 255, 0.8); /* Add a dashed border to the cropping area */
  border-radius: 5px; /* Add some border radius to make it rounded */
  box-shadow: 0 0 0 10000px rgba(0, 0, 0, 0.5); /* Add a semi-transparent black background outside the cropping area */
}

/* Customize the appearance of the handles */
.ReactCrop__handle {
  /* Set the size of the handles */
  width: 10px; /* Adjust the width of the handles */
  height: 10px; /* Adjust the height of the handles */
  background-color: #ffffff; /* Set the color of the handles */
  border: 2px solid #000000; /* Add a border to the handles */
  border-radius: 50%; /* Make the handles circular */
}

/* Customize the appearance of the handle corners */
.ReactCrop__handle-corner {
  /* Set the size of the handle corners */
  width: 15px; /* Adjust the width of the handle corners */
  height: 15px; /* Adjust the height of the handle corners */
  background-color: #ffffff; /* Set the color of the handle corners */
  border: 2px solid #000000; /* Add a border to the handle corners */
  border-radius: 50%; /* Make the handle corners circular */
}


.modal-profile2 {
  width: 300px;
  height: 300px;
}

.confirm-crop-button {
  width: 100px;
  bottom: -130px;
  left: 0px;
  position: relative;
  z-index: 1; /* Set a higher z-index to make it appear above the ReactCrop component */
}

.confirm-crop-button2 {
  width: 100px;
  position: relative;
  z-index: 1; /* Set a higher z-index to make it appear above the ReactCrop component */
}
