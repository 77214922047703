.h1-attendance{
  /*background-color: black;*/
  color: white;
  text-align: right;

}

.p-attendance{
  color: white;
  text-align: center;
}

.body-attendance {
  background-image: url("./SignInBg.png");
  background-size: cover;
  /*display: flex;*/ /*comment to make the sidebar ok*/
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
.attendance-container {
  display: flex;
  height: 100%;
}

.attendance-content {
  margin-left: 15em;
  padding: 20px;
  width: 100%;
}


/*div {

}*/