.h1-username {
    /*background-color: black;*/
    /*color: white;*/
    text-align: right;

  }

.p-username{
    color: black;
    

  }
  .dashboard-container {
    display: flex;
    height: 100%;
  }
  
  .dashboard-content {
    margin-left: 15em;
    padding: 20px;
    width: 100%;
  }

  .circle-username {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    /*border: 2px solid lightgray;*/
  }
  
  .profile-image-username {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .loading-text-username {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .loading-text-username p {
    text-align: center;
  }

  .container-username{
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .p-usrname-container {
    color: #ffffff;
    text-align: center;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5); /* Added text shadow */
    font-size: 18px;
  }

  .p-usrname-background {
    color: #010ece;

  }

  .dropdown-content {
    position: absolute;
    top: 100%; /* Position it below the image */
    /*left: 0;   /* Align it with the left edge of the image */
    right: 0;
    background-color: #fff; /* Set background color */
    border: 1px solid #ccc; /* Add a border for visibility */
    padding: 10px; /* Add padding for spacing */
    z-index: 1; /* Ensure it appears above other elements */
  }

  .username-icon {
    font-size: 30px; /* Adjust the size as desired */
    color: black;
    /*box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.4); /* Add the shadow effect */
  }

  /*.superadmin-font{
  background: -webkit-linear-gradient(rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: right;
  }*/

  .superadmin-font {
    background: -webkit-linear-gradient(rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));
    background: linear-gradient(rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220)); /* Standard linear gradient */
    -webkit-background-clip: text; /* WebKit browsers */
    background-clip: text; /* Standard */
    color: transparent; /* Hide text color */
    text-align: right;
}
