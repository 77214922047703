.button-history-present {
    width: 13%;
    height: 3em;
    border: 0px;
    color: white;
    background: linear-gradient(to right, #662d8c, #e31f7b);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
    font-weight: bold;
  }
  
  .button-history-present:hover {
    /*background: linear-gradient(to left, #23ed3e, #076614);*/
    background: #076614;
  }
  
  .button-history-absent {
    width: 13%;
    height: 3em;
    border: 0px;
    color: white;
    background: linear-gradient(to right, #662d8c, #e31f7b);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
    font-weight: bold;
  }
  
  .button-history-absent:hover {
    /*background: linear-gradient(to right, #ed2377, #f56e59);*/
    background: #ec0404;
  }
  
  .button-history-late {
    width: 13%;
    height: 3em;
    border: 0px;
    color: white;
    background: linear-gradient(to right, #662d8c, #e31f7b);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
    font-weight: bold;
  }
  
  .button-history-late:hover {
    /*background: linear-gradient(to right, #ed2377, #f56e59);*/
    background: #868404;
  }

  .button-delete-class-attendance {
    width: 80px;
    height: 45px;
    border: 0px;
    color: white;
    background: linear-gradient(to right, #662d8c, #e31f7b);
    /*background-color: rgb(190,52,85);*/
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center; /* Align items vertically in the center */
    margin: 5px;
    transition: 0.3s;
    border-radius: 0.4em;
    cursor: pointer;
  }
  
  .button-delete-class-attendance:hover {
    /*background: linear-gradient(to right, #ed2377, #f56e59);*/
    transition: 0.3s;
    background: red;
  }
  
  .button-delete-class-attendance-container {
    display: flex;
    justify-content: center;
  }