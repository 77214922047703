* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto',sans-serif;
}

.body {
    /*background-image: url("../SignInBg.png");*/
    background-size: cover;
    display: flex; /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.page {
    height: 100vh; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-container {
    background-color: white;
    width: 25em;
    height: 40em;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.logo-position-register {
    width: 25em;
    height: 70px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-detail {
    width: 23em;
    height: 13em;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

input {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 27.6em;
    border-radius: 0.25em;
    padding: 1em;
}

.select-size {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 27.6em;
    border-radius: 0.25em;
    padding: 1em;

}

input.focus {
    outline-color: rgb(32, 177, 255);
}

.button-register {
    width: 70%;
    height: 3em;
    border: 0px;
    background-color: rgb(233, 150, 5);
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
    margin: auto;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
}

button:hover { transform: scale(1.10);}

.forgot {
    position:relative; 
    width: 110%;
    height: 2em;
    font-size: 11px;
    left:130px; 
    top:0px;
    color: white;
}

.account-notification {
    position:relative;
    color: Black;
    width: 130%;
    left:-15px; 
    height: 2em;
}

.register-link {
    color: rgb(200, 153, 11);
    transition: 0.3s;
}

.register-link:hover {
    box-shadow: 0 5px 15px #afe0f5;
     transform: scale(1.5);
}

.forgot-password {
    color: rgb(200, 153, 11);
    transition: 0.3s;
}

.forgot-password:hover {
    box-shadow: 0 5px 15px #f5cbaf;
     transform: scale(1.5);
    }

