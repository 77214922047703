.h1-adminedit{
    /*background-color: black;*/
    color: black;
    text-align: center;
  
  }
  
  .p-createclass{
    color: black;
    text-align: center;
  }

  .p-createclass2{
    color: black;
  }

  .p-editclass2{
    color: black;
  }

  .p-createclass3{
    font-weight: bold;
    color: white;
    text-align: center;
  }

  .p-createclass4{
    color: black;
    text-align:justify;
    font-weight: bold;
  }
  
  .body-createclass {
    /*background-image: url("../SignInBg.png");*/
    background-size: cover;
    /*display: flex;*/ /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }
  
  .createclass-content {
    margin-left: 15em;
    padding: 20px;
    width: 100%;
  }
  
  .createclass-container {
    background-color: rgba(112, 40, 40, 0.466);
    width: 80em;
    height: 40em;
    align-items: center;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    justify-content: space-around;
}

.createclass-container3 {
    background-color: rgb(232, 232, 232);
    width: 25em;
    height: 2.3em;
    position: relative;
    border-radius: 1em;
    flex-direction: column;
    justify-content: space-around;
}

.createclass-container4 {
    background-color: rgb(232, 232, 232);
    width: 15em;
    height: 2em;
    position: relative;
    border-radius: 1em;
    flex-direction: column;
    justify-content: space-around;
}

.select-size-createclass {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 25em;
    border-radius: 0.25em;
    padding: 1em;

}

.select-size-createclass2 {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.0em;
    width: 6em;
    border-radius: 0.25em;
    padding: 1em;
}

.select-size-editclass2 {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.0em;
    width: 20em;
    border-radius: 0.25em;
    padding: 1em;
}

.select-size-createclass3 {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.0em;
  width: 5em;
  border-radius: 0.25em;
  padding: 1em;
  margin-left: .5rem;
}

.button-addSubject {
  width: 10%;
  height: 3em;
  border: 0px;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  margin: auto;
  /*margin-left: 10px;*/
  margin-right: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-admineditclass4 {
  width: 140px;
  height: 3em;
  border: 0px;
  font-weight: bold;
  color: white;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  /*left: 150px;*/
  /*margin: auto;*/
  margin-left: -750px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-editclass2 {
  width: 10%;
  height: 4em;
  border: 0px;
  color: white;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  float: left;
  margin: auto;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-admineditclass {
  width: 10%;
  height: 4em;
  border: 0px;
  color: white;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  /*float: left;*/
  margin: auto;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-editclass3 {
    color: white;
    background-color: rgb(190,52,85);
    width: 100%;
    font-weight: bold;
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    display: block;
    margin: auto;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
  }

.table-container {
  overflow: auto;
  max-height: 210px; /* set the maximum height of the container */
  
}

.table-createclass{
  border-collapse: collapse;
  width: 75%;
  /*height: 100%;*/
  height: 350px;
  align-items: center;
  overflow: auto;
  font-size: 14px;
  color: #333;
  display: block;
  
  /*margin-left: 10px;*/
}

.table-editclass{
    border-collapse: collapse;
    width: 100%;
    /*height: 100%;*/
    height: 350px;
    align-items: center;
    overflow: auto;
    font-size: 14px;
    color: #333;
    display: block;
  }

  .editclass-column{
    float: left;
    width: 100%;
    height: 100%;
    padding: 5px;
  }

  .table-container-editclass {
    overflow: auto;
    max-height: 850px; /* set the maximum height of the container */
  }

  .table-container-editclass2 {
    overflow: auto;
    max-height: 400px; /* set the maximum height of the container */
  }

  .input-editclass2 {
    border: 1px solid gray;
    background-color: rgb(232, 232, 232);
    height: 1.5em;
    width: 19em;
    border-radius: 0.25em;
    padding: 1em;
}

.input-editclassnew2 {
  border: 1px solid gray;
  background-color: rgb(232, 232, 232);
  height: 1.5em;
  width: 12em;
  margin-top: 20px;
  margin-right: -12px;
  border-radius: 0.25em;
  padding: 1em;
}

.input-editclassnew3 {
  border: 1px solid gray;
  background-color: rgb(232, 232, 232);
  height: 3.5em;
  width: 19em;
  margin-top: 10px;
  margin-right: -12px;
  /*line-height: 200px;*/
  border-radius: 0.25em;
  padding: 1em;
}

.input-editclassnew4 {
  border: 1px solid gray;
  background-color: rgb(232, 232, 232);
  height: 3.3em;
  width: 19em;
  margin-top: 10px;
  margin-right: -12px;
  /*line-height: 200px;*/
  border-radius: 0.25em;
  padding: 1em;
}

.adjust-adminview-filterby-disp {
  margin-left: 10px;
}

.adjust-adminview-lecname {
  margin-top: 12px;
  border: black;
  background-color: rgb(232, 232, 232);
  height: 40px;
  width: 20em;
  border-radius: 0.25em;
  padding: 1em;
}

.adminview-lecname-boldlight {
font-weight: bolder;

}

.adjust-adminright-filterby {
  /*margin-left: -10px;*/
  margin-left: auto;
}

.adjust-adminright-filterby2 {
  margin-left: 135px;
}

.adjust-adminright-filterby3 {
  margin-left: 320px;
}

.adjust-adminright-filterby4 {
  margin-left: 232px;
}

.container-admin-filterby{
  display: flex;
  align-items: center;
}

.adminview-warning-fontsize {
  font-size: 12px;
}

  .editclass-td{
    table-layout: fixed;
    width: auto;
    }

.container-editclass{
    margin-left: 200px;
}

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: #333;
  /*margin-left: 10px;*/
}

th {
  padding: 10px;
  text-align: left;
  /*border-bottom: 1px solid #ddd;*/
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  /*border-bottom: 1px solid #ddd;*//*change colour of table*/
}

th {
  background-color: #f2f2f2;/*change colour of table*/
  font-weight: bold;
}

tr:hover {
  background-color: #f5f5f5;/*change colour of table*/
}

tr:nth-child(even) {
  background-color: #fafafa;/*change colour of table*/
}

tr:nth-child(odd) {
  background-color: #bdb4b4;/*change colour of table*/
}

.background-rectangle {
  width: 90px; /* Adjust the width as needed */
  height: 45px; /* Adjust the height as needed */
  border-radius: 30px; /* Adjust the border radius as needed */
  background-color: grey; /* Adjust the background color as needed */
}

.toggle-button {
  cursor: pointer;
  border: none;
  padding: 8px;
  border-radius: 30px;
  color: white;
  outline: none;
  transition: margin 0.3s ease; /* Add a transition for the margin property */
  font-weight: bold;
}

.toggle-button.active {
  background-color: green;
  /*float: right;*/
  margin-right: 4px; /* Set the default position for the active state */
}

.toggle-button.inactive {
  background-color: red;
  /*float: left;*/
  margin-right: 23px; /* Set the default position for the active state */
}

.toggle-button.null {
  background-color: grey;
}

.toggle-button:active {
  outline: none;
}

.circle-admin-log {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  transition: margin 0.3s ease; /* Add a transition for the margin property */
}

.circle-admin-log .active {
  background-color: green;
  margin-right: 8px; /* Set the default position for the active state */
}

.circle-admin-log .inactive {
  background-color: red;
  margin-right: -8px; /* Slide the circle to the left for the inactive state */
}

.circle-admin-log .null {
  background-color: grey;
  margin-right: 8px; /* Set the default position for the null state */
}

.button-admindelete-class {
  width: 90px;
  height: 40px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;

  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-admindelete-class:hover {
  background: red;
  transition: 0.3s;
}