* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto',sans-serif;
}

.body-adminsignin {
    background-image: url("./SignInBgbright.png");
    background-size: 100vh;
    background-size: 80vw;
    background-position-x: 400px;
    background-repeat: no-repeat;
    display: flex; /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.page {
    height: 100vh; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-sign-in-container {
    background-color: white;
    /*width: 25em;
    height: 30em;*/
    width: 25em;
    height: 100vh;
    position: absolute;
    top: 0px;
    left:0px;
    /*border-radius: 1em;*/
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.admin-logo-position {
    width: 25em;
    height: 10em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-detail {
    width: 23em;
    height: 13em;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.h2-admin-signin {
    text-align: center;

}

input {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 27.6em;
    border-radius: 0.25em;
    padding: 1em; /*for changing input ui*/
}

input.focus {
    outline-color: rgb(32, 177, 255);
}

button {
    width: 70%;
    height: 3em;
    border: 0px;
    background-color: rgb(190,52,85);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
    margin: auto;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
}

button:hover { 
    transform: scale(1.10);
    background-color: orange;
}

.forgot {
    position:relative; 
    width: 110%;
    height: 2em;
    font-size: 11px;
    left:130px; 
    top:0px;
    color: white;
}

.account-notification {
    position:relative;
    color: Black;
    width: 130%;
    left:-15px; 
    height: 2em;
}

.register-link {
    color: rgb(200, 153, 11);
    transition: 0.3s;
}

.register-link:hover {
    box-shadow: 0 5px 15px #afe0f5;
     transform: scale(1.5);
}

.forgot-password {
    color: rgb(200, 153, 11);
    transition: 0.3s;
}

.forgot-password:hover {
    box-shadow: 0 5px 15px #f5cbaf;
     transform: scale(1.5);
    }

.footer-signin-container {
        position: fixed;
        /*bottom: 0;
        left: 0;*/
        bottom: 0;
        right: 0;
        margin: 10px;
        color: white;
        font-weight: bold;
        font-size: 14px;
        text-shadow: 0 0 5px black, 0 0 2px black, 0 0 2px black, 0 0 10px black; /*add shadow*/
    }