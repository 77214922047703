.h1-logout{
    /*background-color: black;*/
    color: black;
    text-align: center;
  
  }
  
  .p-createclass{
    color: black;
    text-align: center;
  }

  .p-logout2{
    color: white;
    text-align: center;
  }

  .p-createclass3{
    font-weight: bold;
    color: white;
    text-align: center;
  }
  
  .body-logout {
    /*background-image: url("../SignInBg.png");*/
    background-size: cover;
    /*display: flex;*/ /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }
  
  .createclass-content {
    margin-left: 15em;
    padding: 20px;
    width: 100%;
  }
  
  .createclass-container {
    background-color: rgba(112, 40, 40, 0.466);
    width: 80em;
    height: 40em;
    align-items: center;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    justify-content: space-around;
}

.createclass-container3 {
    background-color: rgb(232, 232, 232);
    width: 25em;
    height: 2.3em;
    position: relative;
    border-radius: 1em;
    flex-direction: column;
    justify-content: space-around;
}

.createclass-container4 {
    background-color: rgb(232, 232, 232);
    width: 15em;
    height: 2em;
    position: relative;
    border-radius: 1em;
    flex-direction: column;
    justify-content: space-around;
}

.select-size-createclass {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 25em;
    border-radius: 0.25em;
    padding: 1em;

}

.select-size-createclass2 {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 11em;
    border-radius: 0.25em;
    padding: 1em;

}

.button-logout {
  width: 10%;
  height: 3em;
  border: 0px;
  font-weight: bold;
  color: white;
  background-color: rgb(190,52,85);
  display: flex;
  justify-content: center;
  align-items: center;
  display: block;
  margin: auto;
  /*margin-left: 10px;*/
    /*margin-top: 200px;*/
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-logout:hover {
  background-color:red;
}

.button-logout-new {
  width: 10%;
  height: 3em;
  border: 0px;
  font-weight: bold;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  display: flex;
  justify-content: center;
  align-items: center;
  display: block;
  margin: auto;
  /*margin-left: 10px;*/
    /*margin-top: 200px;*/
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-logout-new:hover {
  background:red;
}