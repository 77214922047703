.h1-addstudent {
    /*background-color: black;*/
    color: black;
    text-align: right;
    text-align: center;
   /* float: right;*/
  }

  .p-qrattend{
    color: black;
  }

  .p-addstudent2{
    color: white;
  }

  .p-generateqr{
    color: black;
    /*font-weight: bold;*/
    font-size: 20px;
  }

  .p-history2{
    color: black;
    /*font-weight: bold;*/
    font-size: 16px;
    text-align: left;
  }

  .p-generateqr3{
    color: black;
    /*font-weight: bold;*/
    align-items: center;
    text-align: center;
    margin: auto;
    display: block;
  }

  .p-generateqr4{
    color: black;
    /*font-weight: bold;*/
    align-items: center;
    text-align: center;
    margin: auto;
    display: block;
    font-weight: bold;
  }

  .p-generateqr-sidenote{
    color: black;
    font-size: 13px;
  }

  .p-addstudent3{
    color: black;
    text-align: center;
  }

  .p-addstudent4{
    color: black;
    text-align: center;
    font-weight: bold;
  }

  .body-addstudent {
    /*background-image: url("../SignInBg.png");*/
    background-size: cover;
    /*display: flex;*/ /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    overflow: auto;
    /*height: 100vh;
    width: 100vw;*/
}

.addstudent-container {
    background-color: rgba(112, 40, 40, 0.466);
    width: 80em;
    height: 40em;
    align-items: center;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    justify-content: space-around;
}

.addstudent-container2 {
  background-color: rgb(232, 232, 232);
  width: 15em;
  height: 3em;
  position: relative;
  border-radius: 1em;
  flex-direction: column;
  justify-content: space-around;
}

.button-history2 {
  width: 13%;
  height: 3em;
  border: 0px;
  color: white;
  background-color: rgb(190,52,85);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
  font-weight: bold;
}

.button-history2:hover {
background-color: orange;
}

.button-history4 {
  width: 13%;
  height: 3em;
  border: 0px;
  color: black;
  background-color: rgb(255, 255, 255);
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
  font-weight: bold;
}

.button-history4:hover {
background-color: orange;
}

.button-history3 {
  width: 15%;
  height: 3em;
  border: 0px;
  color: white;
  background-color: rgb(190,52,85);
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
  font-weight: bold;
}

.history-search-container{
  float: right;
}

.container-history {
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-History {
  border: 1px solid gray;
  background-color: rgb(232, 232, 232);
  height: 3.5em;
  width: 10.5em;
  border-radius: 0.25em;
  padding: 1em;
}

.button-generateqr {
    width: 100px;
    height: 3em;
    border: 0px;
    color: white;
    background-color: rgb(190,52,85);
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    display: block;
    left: 150px;
    margin: auto;
    margin-left: 10px;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
  }

.checkbox-size-addstudent {
  font-family: system-ui, sans-serif;
  font-size: 5px;
  font-weight: bold;
  line-height: 1.1;

}

.select-size-generateqr {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.5em;
  width: 25em;
  border-radius: 0.25em;
  padding: 1em;
  align-items: center;
  display: block;
  margin: auto;
  text-align: center;
  font-weight: bold;
}

.select-size-addstudent2 {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.0em;
  width: 6em;
  border-radius: 0.25em;
  padding: 1em;
}

.select-size-addstudent3 {
border: none;
background-color: rgb(232, 232, 232);
height: 3.0em;
width: 5em;
border-radius: 0.25em;
padding: 1em;
margin-left: .5rem;
}

.addstudent-row {
  margin-left:-5px;
  margin-right:-5px;
}

.addstudent-
{
  float: left;
  width: 50%;
  padding: 5px;
}



.button-addStudent3 {
  width: 13%;
  height: 3em;
  border: 0px;
  float: left;
  color: white;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.table-history {
  border-collapse: collapse;
  width: 100%;
  /*height: 100%;*/
  height: 450px;
  align-items: center;
  overflow: auto;
  font-size: 14px;
  color: #333;
  display: block;
  
  /*margin-left: 10px;*/
}

.generateqr-td{
table-layout: fixed;
width: 450px;
}

.generateqr-td .cell-content {
  max-height: 100px;
  overflow: auto;
}

.generateqr-column{
  float: left;
  width: 50%;
  padding: 5px;

}

.th-generateqr {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.p-createclass{
  color: black;
  text-align: center;
}

.addstudent-container3 {
  background-color: rgb(232, 232, 232);
  width: 30em;
  height: 2.3em;
  position: relative;
  /*margin-left: 10px;*/
  border-radius: 10px;
  transition: 0.3s;
  flex-direction: column;
  justify-content: space-around;
}

.button-history3 {
  color: white;
  background-color: rgb(190,52,85);
  width: 35%;
  font-weight: bold;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  margin: auto;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-addStudent4 {
  width: 5%;
  height: 2.3em;
  border: 0px;
  /*float: left;*/
  background-color:rgb(232, 232, 232);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: -17px;
  transition: 0.3s;
  border-radius: 12px;
  cursor: pointer;
}

.find-student-position{
  margin-left: 10px;
  align-items: center;
}

.addstudent-next-to{
  display: flex;
  align-items: center; /* Align elements vertically in the center */
  gap: 10px; /* Add some space between elements */
  
}

.scrollpop{
  overflow-y: auto;
}

/*.table-container {
  display: flex;
  overflow: auto;
  max-height: 210px; /* set the maximum height of the container */
  /*max-width: 600px; /* set the maximum height of the container */
/*}*/

/*.student-list {
  margin-right: 20px; /* Adjust spacing between the lists */
/*}*/