.h1-qrattendancepage {
  /* background-color: black; */
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  /*-webkit-text-stroke-width: 1.5px;
  /*-webkit-text-stroke-color: black;
  margin-top: 50px;
  font-size: 40px; /* Increased font size */
  margin-left: 10px;
  font-weight: bold;
  font-family:Arial, Helvetica, sans-serif;
  letter-spacing: 2px; /* Increased letter spacing */
  text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5); /* Added text shadow */
}

.background-qrattendance-new{
  margin-left: 100px;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  /*background-image: url("./qrbackground.png");*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 200vh;
  width: 100vw;
  /*display: flex;*/
  /*justify-content: center;
  align-items: center;*/
}

  .p-qrattendancepage{
    color: black;
  }

  .body-qrattendancepage {
    /*background-image: url("../SignInBg.png");*/
    background-size: cover;
    /*display: flex;*/ /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}
  .dashboard-container {
    display: flex;
    height: 100%;
  }
  
  .dashboard-content {
    margin-left: 15em;
    padding: 20px;
    width: 100%;
  }

  .table-qrattendance {
    margin-top: 10px;
    margin-left: 10px;
    border-collapse: collapse;
    width: 90%;
    /*height: 100%;*/
    height: 450px;
    align-items: center;
    overflow: auto;
    font-size: 14px;
    color: #333;
    display: block;
    
    /*margin-left: 10px;*/
  }

.background-qrattendance{
  margin-left: 100px;
  background-color: rgb(190,52,85);
  /*background-image: url("./qrbackground.png");*/
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 200vh;
  width: 100vw;
  /*display: flex;*/
  /*justify-content: center;
  align-items: center;*/
}

.background-qrattendance2{
  background-image: url("./qrbackground.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100vh;
  width: 100vw;

}