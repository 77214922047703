.h1-admineditstudent {
    /*background-color: black;*/
    color: black;
    text-align: right;
    text-align: center;
   /* float: right;*/
  }

  .editstudent-td{
    table-layout: fixed;
    width: auto;
    }

    .table-editstudent{
      border-collapse: collapse;
      width: 100%;
      /*height: 100%;*/
      height: 400px;
      align-items: center;
      overflow: auto;
      font-size: 14px;
      color: #333;
      display: block;
    }

    .table-editstudent2{
      border-collapse: collapse;
      width: 100%;
      /*height: 100%;*/
      height: 250px;
      align-items: center;
      overflow: auto;
      font-size: 14px;
      color: #333;
      display: block;
    }

.modal {
font-size: 12px; 
}

.modal > .header {  
width: 100%; 
border-bottom: 1px solid gray;
font-size: 18px;  
text-align: center;  
padding: 5px;
}

.modal > .content {
width: 100%;
padding: 10px 5px;
}

.modal > .actions {
width: 100%;
padding: 10px 5px;
margin: auto;
text-align: center;
}

.modal > .close {  
cursor: pointer;
position: absolute; 
display: block;  
padding: 2px 5px;  
line-height: 20px;  
right: -10px;  
top: -10px;  
font-size: 24px;  
background: #ffffff;  
border-radius: 18px;  
border: 1px solid #cfcece;
}

.adm-editstud-center{
  display: flex;
  justify-content: center;
   align-items: center;
   /*height: 100vh;*/
}

.button-admindelete-class {
  width: 90px;
  height: 40px;
  border: 0px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;

  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-admindelete-class:hover {
  background: red;
  transition: 0.3s;
}