.h1-addstudent {
    /*background-color: black;*/
    color: black;
    text-align: right;
    text-align: center;

  }

  .h2-studentclass {
    /*background-color: black;*/
    color: black;
    text-align: right;
    text-align: center;

  }

  .p-studentclass{
    color: white;
  }

  .p-studentclass2{
    color: black;
  }

  .p-studentclass-sidenote{
    color: black;
    font-size: 13px;
  }

  .p-addstudent3{
    color: black;
    text-align: center;
  }

  .p-addstudent4{
    color: black;
    text-align: center;
    font-weight: bold;
  }

  .body-addstudent {
    /*background-image: url("../SignInBg.png");*/
    background-size: cover;
    /*display: flex;*/ /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.addstudent-container {
    background-color: rgba(112, 40, 40, 0.466);
    width: 80em;
    height: 40em;
    align-items: center;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    justify-content: space-around;
}

.addstudent-container2 {
  background-color: rgb(232, 232, 232);
  width: 15em;
  height: 3em;
  position: relative;
  border-radius: 1em;
  flex-direction: column;
  justify-content: space-around;
}

.studentclass-container3 {
  background-color: rgb(192, 200, 207);
  width: 80em;
  height: 40em;
  align-items: center;
  position: relative;
  border-radius: 1em;
  box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
  justify-content: space-around;
}

.button-addStudent2 {
  width: 13%;
  height: 3em;
  border: 0px;
  color: white;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-studentclass {
  width: 18%;
  height: 3em;
  color: white;
  background-color: rgb(190,52,85);
  font-weight: bold;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  margin: auto;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-studentclass:hover {
  background-color: orange;
}

.button-studentclass2 {
  width: 13%;
  height: 3em;
  border: 0px;
  color: white;
  background-color: rgb(190,52,85);
  font-weight: bold;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  margin: auto;
  margin-left: 1px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-studentclass2:hover {
background-color: orange;
}

/*.button-studentclass3 {
  background-color: rgb(190,52,85);
  color: white;
  font-weight: bold;
  display: block;
  margin: auto;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-studentclass3:hover {
background-color: orange;
}*/

.button-studentclass-new3 {
  background: linear-gradient(to right, #662d8c, #e31f7b);
  color: white;
  font-weight: bold;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  margin: auto;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-studentclass-new3:hover {
  background: linear-gradient(to right, #ed2377, #f56e59);
}

.button-studentclass5 {
  width: 100px;
  height: 40px;
  border: 0px;
  color: white;
  background-color: rgb(190,52,85);
  font-weight: bold;
  font-size: 12px;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  margin: auto;
  margin-left: 1px;
  transition: 0.3s;
  border-radius: 0.4em;
  cursor: pointer;
}

.button-studentclass5:hover {
background-color: orange;
}

.checkbox-size-addstudent {
  font-family: system-ui, sans-serif;
  font-size: 5px;
  font-weight: bold;
  line-height: 1.1;

}

.select-size-addstudent {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.5em;
  width: 25em;
  border-radius: 0.25em;
  padding: 1em;
}

.select-size-addstudent2 {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.0em;
  width: 6em;
  border-radius: 0.25em;
  padding: 1em;
}

.select-size-addstudent3 {
border: none;
background-color: rgb(232, 232, 232);
height: 3.0em;
width: 5em;
border-radius: 0.25em;
padding: 1em;
margin-left: .5rem;
}

.studentclass-row {
  margin-left:-5px;
  margin-right:-5px;
}

.studentclass-column{
  float: left;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.button-addStudent3 {
  width: 13%;
  height: 3em;
  border: 0px;
  float: left;
  color: white;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.table-studentclass {
  border-collapse: collapse;
  width: 100%;
  /*height: 100%;*/
  height: 550px;
  align-items: center;
  overflow: auto;
  font-size: 14px;
  color: #333;
  display: block;
  
  /*margin-left: 10px;*/
}
.table-studentclass2 {
  border-collapse: collapse;
  width: 100%;
  /*height: 100%;*/
  height: 450px;
  align-items: center;
  overflow: auto;
  font-size: 14px;
  color: #333;
  display: block;
  
  /*margin-left: 10px;*/
}

.th-addstudent {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  
}

/*.button-studentclass4 {
  width: 5%;
  height: 30px;
  border: 0px;
  float: right;
  font-size: 24px;
  color: white;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  /*display: block;
  transition: 0.3s;
  border-radius: 5px;
  cursor: pointer;*/
/*}*/

/*.button-studentclass4:hover {
  background-color: red;
  }*/

.button-studentclass4 {
  width: 5%;
  height: 30px;
  border: 0px;
  float: right;
  font-size: 24px;
  color: white;
  background: linear-gradient(to right, #662d8c, #e31f7b);
 /*background: linear-gradient(to right, #662d8c, #e31f7b);*/
  /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  transition: 0.3s;
  border-radius: 5px;
  cursor: pointer;
}

.button-studentclass4:hover {
background: red;
}

.modal {
font-size: 12px; 
}

.modal > .header {  
width: 100%; 
border-bottom: 1px solid gray;
font-size: 18px;  
text-align: center;  
padding: 5px;
}

.modal > .content {
width: 100%;
padding: 10px 5px;
}

.modal > .actions {
width: 100%;
padding: 10px 5px;
margin: auto;
text-align: center;
}

.modal > .close {  
cursor: pointer;
position: absolute; 
display: block;  
padding: 2px 5px;  
line-height: 20px;  
right: -10px;  
top: -10px;  
font-size: 24px;  
background: #ffffff;  
border-radius: 18px;  
border: 1px solid #cfcece;
}

.table-container-createclass {
  overflow: auto;
  max-height: 450px; /* set the maximum height of the container */
}

.table-container-studentclass {
  overflow: auto;
  max-height: 750px; /* set the maximum height of the container */
}

.studentclass-icon {
  font-size: 30px;
}