* {
    margin: 0;
    box-sizing: border-box;
    font-family: 'Roboto',sans-serif;
}

.body-forgot-password-container {
    background-image: url("./SignInBgbright.png");
    /*background-size: contain;
    background-repeat: no-repeat;*/
    background-size: 100vh;
    background-size: 80vw;
    background-position-x: 400px;
    background-repeat: no-repeat;
    display: flex; /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.page {
    height: 100vh; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgotpassword-container {
    background-color: white;
   /* width: 25em;
    height: 20em;*/
    width: 25em;
    height: 100vh;
    /*position: relative;*/
    position: absolute;
    top: 0px;
    left:0px;
    /*border-radius: 0em;*/
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.logo-position-register {
    width: 25em;
    height: 70px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-detail {
    width: 23em;
    height: 13em;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

input {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 27.6em;
    border-radius: 0.25em;
    padding: 1em;
}

.select-size {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 27.6em;
    border-radius: 0.25em;
    padding: 1em;

}

input.focus {
    outline-color: rgb(32, 177, 255);
}

/*.button-forgotpassword{
    width: 70%;
    height: 3em;
    border: 0px;
    color: white;
    background-color: rgb(190,52,85);
    display: flex;
    justify-content: center;
    align-items: center;
    display: block;
    margin: auto;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
}*/

.button-forgotpassword {
    width: 350px;
    height: 35px;
    border: 0px;
    color: white;
    /*background: linear-gradient(135deg, rgb(190,52,85), rgb(255,155,0), rgb(52, 170, 220));*/
    background: linear-gradient(to right, #662d8c, #e31f7b);
    /*background: linear-gradient(to right, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center; /* Align items vertically in the center */
    margin: 5px;
    transition: 0.3s;
    border-radius: 0.9em;
    cursor: pointer;
  }

  .button-forgotpassword:hover {
    background: linear-gradient(to right, #ed2377, #f56e59);
  }
  
  .button-signin-user:hover {
    background: linear-gradient(to right, #ed2377, #f56e59);
    /*background: linear-gradient(to left, rgb(235, 20, 70), rgb(128, 22, 113), rgb(52, 170, 220));*/
  }

button:hover { transform: scale(1.10);}

.forgot {
    position:relative; 
    width: 110%;
    height: 2em;
    font-size: 11px;
    left:130px; 
    top:0px;
    color: white;
}

.account-notification {
    position:relative;
    color: Black;
    width: 130%;
    left:-15px; 
    height: 2em;
}

.register-link-user {
    color: rgb(230, 92, 0);
    transition: 0.3s;
}

.register-link-user:hover {
    box-shadow: 0 5px 15px #ff15ff;
    transform: scale(1.5);
    color: darkblue;
}

.forgot-password {
    color: rgb(200, 153, 11);
    transition: 0.3s;
}

.forgot-password:hover {
    box-shadow: 0 5px 15px #f5cbaf;
     transform: scale(1.5);
    }

