.h1-adminregister {
    /*background-color: black;*/
    color: black;
    text-align: right;
    text-align: center;
  }

.body-adminregister {
    /*background-image: url("../SignInBg.png");*/
    /*background-size: cover;*/
    /*display: flex;*/ /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    overflow: auto;
    /*height: 100vh;*/
    /*width: 100vw;*/
}

.button-adminregister {
    width: 13%;
    height: 3em;
    border: 0px;
    /*float: left;*/
    color: white;
    background-color: rgb(190,52,85);
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    display: block;
    /*left: 150px;*/
    margin: auto;
    /*margin-left: 110px;*/
    justify-items: center;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
  }

  .button-adminregister2 {
    width: 100%;
    height: 3em;
    border: 0px;
    float: left;
    color: white;
    background-color: rgb(190,52,85);
    /*display: flex;
    justify-content: center;
    align-items: center;*/
    display: block;
    /*left: 150px;*/
    margin: auto;
    /*margin-left: 110px;*/
    /*justify-items: left;*/
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
  }

  .button-adminregister3 {
    width: 10%;
    height: 3em;
    border: 0px;
    color: white;
    background-color: rgb(190,52,85);
    display: block;
    margin: auto;
    transition: 0.3s;
    border-radius: 0.25em;
    cursor: pointer;
    float: left;
  }

.p-adminregister2{
    color: black;
  }

.p-adminregister3{
    color: White;
    text-align: center;
    font-weight: bold;
}

.checkbox-size-adminregister {
    outline: 1px solid black;
    font-family: system-ui, sans-serif;
    font-size: 5px;
    font-weight: bold;
    line-height: 1.1;
  
  }

.wrapper-adminregister {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.page-adminregister {
    height: 100vh; 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register-container-adminregister {
    background-color: white;
    width: 25em;
    height: 40em;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    margin-left: 100px;
    justify-content: space-around;
}

.logo-position-adminregister {
    width: 25em;
    height: 10em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*.user-detail-adminregister {
    width: 23em;
    height: 13em;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}*/

.input-adminregister {
    border: 1px solid gray;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 27.6em;
    border-radius: 0.25em;
    padding: 1em;
}

.input-adminregister2 {
    border: 1px solid gray;
    background-color: rgb(232, 232, 232);
    height: 1.5em;
    width: 15.5em;
    border-radius: 0.25em;
    padding: 1em;
}

.select-size-adminregister {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 27.6em;
    border-radius: 0.25em;
    padding: 1em;
}

.adminregister-search-container{
    float: right;
}

input.focus {
    outline-color: rgb(32, 177, 255);
}

.table-container-adminregister {
    overflow: auto;
    max-height: 450px; /* set the maximum height of the container */
    
  }

.forgot-adminregister {
    position:relative; 
    width: 110%;
    height: 2em;
    font-size: 11px;
    left:130px; 
    top:0px;
    color: white;
}

.account-notification {
    position:relative;
    color: Black;
    width: 130%;
    left:-15px; 
    height: 2em;
}

.register-link {
    color: rgb(200, 153, 11);
    transition: 0.3s;
}

.register-link:hover {
    box-shadow: 0 5px 15px #afe0f5;
     transform: scale(1.5);
}

.forgot-password-adminregister {
    color: rgb(200, 153, 11);
    transition: 0.3s;
}

.forgot-password:hover {
    box-shadow: 0 5px 15px #f5cbaf;
     transform: scale(1.5);
    }

.adminregister-container {
        background-color: rgba(112, 40, 40, 0.466);
        width: 80em;
        height: 40em;
        align-items: center;
        position: relative;
        border-radius: 1em;
        box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
        justify-content: space-around;
    }

    .button-delete-adminregister {
        width: 150px;
        height: 40px;
        border: 0px;
        color: white;
        background: linear-gradient(to right, #662d8c, #e31f7b);
        font-weight: bold;
        font-size: 12px;
        display: flex;
        align-items: center; /* Align items vertically in the center */
        margin: center;
        transition: 0.3s;
        border-radius: 0.4em;
        cursor: pointer;
      }
      
      .button-delete-adminregister:hover {
        background: red;
        transition: 0.3s;
      }