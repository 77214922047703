.h1-createclass{
    /*background-color: black;*/
    color: black;
    text-align: center;
  
  }
  
  .p-createclass{
    color: black;
    text-align: center;
  }

  .p-createclass2{
    color: black;
  }

  .important-createclass{
    color: red;
  }

  .p-createclass3{
    font-weight: bold;
    color: white;
    text-align: center;
  }

  .p-createclass4{
    color: black;
    text-align:justify;
    font-weight: bold;
  }
  
  .body-createclass {
    /*background-image: url("../SignInBg.png");*/
    background-size: cover;
    /*display: flex;*/ /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }
  
  .createclass-content {
    margin-left: 15em;
    padding: 20px;
    width: 100%;
  }
  
  .createclass-container {
    background-color: rgba(112, 40, 40, 0.466);
    width: 80em;
    height: 40em;
    align-items: center;
    position: relative;
    border-radius: 1em;
    box-shadow: 0 0.188em 1.550em rgb(156, 156, 156);
    justify-content: space-around;
}

.createclass-container3 {
    background-color: rgb(232, 232, 232);
    width: 25em;
    height: 2.3em;
    position: relative;
    border-radius: 1em;
    flex-direction: column;
    justify-content: space-around;
}

.createclass-container4 {
    background-color: rgb(232, 232, 232);
    width: 15em;
    height: 2em;
    position: relative;
    border-radius: 1em;
    flex-direction: column;
    justify-content: space-around;
}

.select-size-createclass {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.5em;
    width: 25em;
    border-radius: 0.25em;
    padding: 1em;

}

.select-size-createclass2 {
    border: none;
    background-color: rgb(232, 232, 232);
    height: 3.0em;
    width: 6em;
    border-radius: 0.25em;
    padding: 1em;
}

.select-size-createclass3 {
  border: none;
  background-color: rgb(232, 232, 232);
  height: 3.0em;
  width: 5em;
  border-radius: 0.25em;
  padding: 1em;
  margin-left: .5rem;
}

.button-addSubject {
  width: 10%;
  height: 3em;
  border: 0px;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  margin: auto;
  /*margin-left: 10px;*/
  margin-right: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-addSubject2 {
  width: 10%;
  height: 4em;
  border: 0px;
  color: white;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: inline-block;
  margin: auto;
  margin-left: 20.5rem;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-addSubject2:hover {
background-color: orange;
}

.button-addSubject3 {
  width: 140px;
  height: 3em;
  border: 0px;
  font-weight: bold;
  color: white;
  background-color: rgb(190,52,85);
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  display: block;
  left: 150px;
  margin: auto;
  margin-left: 10px;
  transition: 0.3s;
  border-radius: 0.25em;
  cursor: pointer;
}

.button-addSubject3:hover {
  background-color: orange;
}

.table-container {
  overflow: auto;
  max-height: 210px; /* set the maximum height of the container */
  
}

.table-createclass{
  border-collapse: collapse;
  width: 75%;
  /*height: 100%;*/
  height: 350px;
  align-items: center;
  overflow: auto;
  font-size: 14px;
  color: #333;
  display: block;
  
  /*margin-left: 10px;*/
}

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  font-size: 14px;
  color: #333;
  /*margin-left: 10px;*/
}

th {
  padding: 10px;
  text-align: left;
  /*border-bottom: 1px solid #ddd;*/
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  /*border-bottom: 1px solid #ddd;*//*change colour of table*/
}

th {
  background-color: #f2f2f2;/*change colour of table*/
  font-weight: bold;
}

tr:hover {
  background-color: #f5f5f5;/*change colour of table*/
}

tr:nth-child(even) {
  background-color: #fafafa;/*change colour of table*/
}

tr:nth-child(odd) {
  background-color: #bdb4b4;/*change colour of table*/
}

.input-icon-container {
  position: relative;
  display: inline-block;
}

.search-icon {
  position: absolute;
  font-size: 25px;
  top: 48%;
  right: 1.5%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: transform 0.3s ease;
}

.search-icon:hover {
  transform: translateY(-50%) scale(1.2);
}

.input-createclass {
  border: 1px solid gray;
  background-color: rgb(232, 232, 232);
  height: 1.5em;
  width: 18.5em;
  border-radius: 0.25em;
  padding-right: 40px;
}