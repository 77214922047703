.h1-student {
    /*background-color: black;*/
    color: white;
    text-align: right;

  }

  .p-student{
    color: white;
  }

  .body-student {
    background-image: url("./SignInBg.png");
    background-size: cover;
    /*display: flex;*/ /*comment to make the sidebar ok*/
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}